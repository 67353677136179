export const en = {
  merchant: {
    Order_Overview: "Order Overview",
    Payment_Information: {
      Card_Details: "Card Details",
      LabelAmount: "Payment Amount",
      AmountError:
        "The value of the number must be up to two decimal number only",
      fields: {
        Name_on_Card: "Name on Card",
        Name_on_Card_For_Ibec: "Name on Card",
        FirstName: "First Name",
        FirstName_For_Ibec: "First Name",
        LastName: "Last Name",
        LastName_For_Ibec: "Last Name",
        CardNumber: "Card Number",
        Amount: "Amount",
        CVV: "CVV",
        Card_Expiry: "Card Expiry",
        monthyear: "MM/YY",
        Memo: "Memo",
        MemoPlaceholder: "Memo (Optional)",
      },
    },
    Billing_Information: {
      label: "Billing Information",
      fields: {
        EmailAddress: "Email Address",
        Phone: "Phone",
        BuildingNameStreetForIBEC: "Building Name, Street",
        BuildingNameStreet: "Building Name, Street",
        State: "State",
        Select: "Select...",
        Postal_Code_Label: "Postal Code",
        Postal_Code_Label_For_Ibec: "Postal Code",
        Postal_Code_Placeholder: "Postal Code",
        Postal_Code_Placeholder_For_Ibec: "Postal Code",
        AddressForIBEC: "Address",
        Address: "Address",
        CityStateLabel: "City/State",
        CityStateForIBEC: "City/State",
        CityState: "City/State",
        Country: "Country",
      },
    },
    button: {
      cancel: "Cancel",
      PayNow: "Pay Now",
      SubscribeNow: "Subscribe now",
    },
    Empty_Popup_Please_Enter_An_Amount: "Please enter an amount",
  },
  error404: {
    code: 404,
    message: "Page Not found",
    description:
      "Sorry, the page you are looking for cannot be found or no longer exists.",
  },
  error500: {
    code: 500,
    message: "Server Error Occurred",
    description: "Sorry we are experiencing technical problems.",
  },
  errorcancel499: {
    code: 499,
    message: "Payment Cancelled",
    heading: "Payment has been cancelled and you will not be charged.",
    description_WithCancelUrl:
      "Your browser will automatically be redirected to the merchant's website. If you do not wish to visit that page, you may proceed to close your browser tab.",
    description_WithoutCancelUrl: "You may proceed to close your browser tab.",
  },
  error400: {
    code: 400,
    message: "Bad Request",
    description: "Sorry we are experiencing technical problems.",
  },
  Payment_Failed: {
    status: "Payment Failed!",
    message: "We could not process your payment of",
    Prev_Link: "Back To Merchant",
  },
  Payment_Successful: {
    status: "Payment Successful!",
    recipient: "Paid to:",
    Amount_Paid: "Amount Paid:",
    Transaction_reference: "Transaction reference:",
    Payment_Method: "Payment Method:",
    Prev_Link: "Back To Merchant",
    Redirect_Prefix_Text: "This page will redirect in",
    Redirect_Suffix_Text: "seconds",
  },
  Validation: {
    ExpiryDate: "Expiry date must be greater than today.",
    InvalidCard: "This card is not valid.",
  },
  ProductPopup: {
    Total_Price: "Total Price",
  },
  Email_ID: "Email ID",
  Email_ID_For_Ibec: "メールアドレス",
  Email_Sent_Successfully: "Email Sent Successfully !",
  Please_Enter_An_Email_ID_To_Receive_The_Receipt:
    "Please enter an email id to receive the receipt:",
  Please_Enter_An_Email_ID_To_Receive_The_Receipt_For_Ibec:
    "決済完了メールを受け取りたい場合は、メールアドレスを入力して「送信」を押してください。",
  Subscription: {
    label: {
      PlanName: "Plan Name",
      BillingCycle: "Billing Cycle",
      StartBillingDate: "Start Billing Date",
      EndBillingDate: "End Billing Date",
      TrialPeriod: "Trial Period",
      Discount: "Discount",
    },
    value: {
      ForTheFirst: "For the first",
      Discount: "discount",
      months: "months",
      month: "month",
    },
  },
  countryNames: {
    Afghanistan: "Afghanistan",
    "Åland Islands": "Åland Islands",
    Albania: "Albania",
    Algeria: "Algeria",
    "American Samoa": "American Samoa",
    Andorra: "Andorra",
    Angola: "Angola",
    Anguilla: "Anguilla",
    Antarctica: "Antarctica",
    "Antigua and Barbuda": "Antigua and Barbuda",
    Argentina: "Argentina",
    Armenia: "Armenia",
    Aruba: "Aruba",
    Australia: "Australia",
    Austria: "Austria",
    Azerbaijan: "Azerbaijan",
    Bahamas: "Bahamas",
    Bahrain: "Bahrain",
    Bangladesh: "Bangladesh",
    Barbados: "Barbados",
    Belarus: "Belarus",
    Belgium: "Belgium",
    Belize: "Belize",
    Benin: "Benin",
    Bermuda: "Bermuda",
    Bhutan: "Bhutan",
    Bolivia: "Bolivia",
    "Bosnia and Herzegovina": "Bosnia and Herzegovina",
    Botswana: "Botswana",
    "Bouvet Island": "Bouvet Island",
    Brazil: "Brazil",
    "British Indian Ocean Territory": "British Indian Ocean Territory",
    "Brunei Darussalam": "Brunei Darussalam",
    Bulgaria: "Bulgaria",
    "Burkina Faso": "Burkina Faso",
    Burundi: "Burundi",
    Cambodia: "Cambodia",
    Cameroon: "Cameroon",
    Canada: "Canada",
    "Cape Verde": "Cape Verde",
    "Cayman Islands": "Cayman Islands",
    "Central African Republic": "Central African Republic",
    Chad: "Chad",
    Chile: "Chile",
    China: "China",
    "Christmas Island": "Christmas Island",
    "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
    Colombia: "Colombia",
    Comoros: "Comoros",
    Congo: "Congo",
    "Congo, The Democratic Republic of the":
      "Congo, The Democratic Republic of the",
    "Cook Islands": "Cook Islands",
    "Costa Rica": "Costa Rica",
    "Cote D'Ivoire": "Cote D'Ivoire",
    Croatia: "Croatia",
    Cuba: "Cuba",
    Cyprus: "Cyprus",
    "Czech Republic": "Czech Republic",
    Denmark: "Denmark",
    Djibouti: "Djibouti",
    Dominica: "Dominica",
    "Dominican Republic": "Dominican Republic",
    Ecuador: "Ecuador",
    Egypt: "Egypt",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Equatorial Guinea",
    Eritrea: "Eritrea",
    Estonia: "Estonia",
    Ethiopia: "Ethiopia",
    "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
    "Faroe Islands": "Faroe Islands",
    Fiji: "Fiji",
    Finland: "Finland",
    France: "France",
    "French Guiana": "French Guiana",
    "French Polynesia": "French Polynesia",
    "French Southern Territories": "French Southern Territories",
    Gabon: "Gabon",
    Gambia: "Gambia",
    Georgia: "Georgia",
    Germany: "Germany",
    Ghana: "Ghana",
    Gibraltar: "Gibraltar",
    Greece: "Greece",
    Greenland: "Greenland",
    Grenada: "Grenada",
    Guadeloupe: "Guadeloupe",
    Guam: "Guam",
    Guatemala: "Guatemala",
    Guernsey: "Guernsey",
    Guinea: "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    Guyana: "Guyana",
    Haiti: "Haiti",
    "Heard Island and Mcdonald Islands": "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)": "Holy See (Vatican City State)",
    Honduras: "Honduras",
    "Hong Kong": "Hong Kong",
    Hungary: "Hungary",
    Iceland: "Iceland",
    India: "India",
    Indonesia: "Indonesia",
    "Iran,Islamic Republic Of": "Iran,Islamic Republic Of",
    Iraq: "Iraq",
    Ireland: "Ireland",
    "Isle of Man": "Isle of Man",
    Israel: "Israel",
    Italy: "Italy",
    Jamaica: "Jamaica",
    Japan: "Japan",
    Jersey: "Jersey",
    Jordan: "Jordan",
    Kazakhstan: "Kazakhstan",
    Kenya: "Kenya",
    Kiribati: "Kiribati",
    "Korea, Democratic People's Republic of":
      "Korea, Democratic People's Republic of",
    "Korea, Republic of": "Korea, Republic of",
    Kuwait: "Kuwait",
    Kyrgyzstan: "Kyrgyzstan",
    "Lao People'S Democratic Republic": "Lao People'S Democratic Republic",
    Latvia: "Latvia",
    Lebanon: "Lebanon",
    Lesotho: "Lesotho",
    Liberia: "Liberia",
    "Libyan Arab Jamahiriya": "Libyan Arab Jamahiriya",
    Liechtenstein: "Liechtenstein",
    Lithuania: "Lithuania",
    Luxembourg: "Luxembourg",
    Macao: "Macao",
    "Macedonia, The Former Yugoslav Republic of":
      "Macedonia, The Former Yugoslav Republic of",
    Madagascar: "Madagascar",
    Malawi: "Malawi",
    Malaysia: "Malaysia",
    Maldives: "Maldives",
    Mali: "Mali",
    Malta: "Malta",
    "Marshall Islands": "Marshall Islands",
    Martinique: "Martinique",
    Mauritania: "Mauritania",
    Mauritius: "Mauritius",
    Mayotte: "Mayotte",
    Mexico: "Mexico",
    "Micronesia, Federated States of": "Micronesia, Federated States of",
    "Moldova, Republic of": "Moldova, Republic of",
    Monaco: "Monaco",
    Mongolia: "Mongolia",
    Montserrat: "Montserrat",
    Morocco: "Morocco",
    Mozambique: "Mozambique",
    Myanmar: "Myanmar",
    Namibia: "Namibia",
    Nauru: "Nauru",
    Nepal: "Nepal",
    Netherlands: "Netherlands",
    "Netherlands Antilles": "Netherlands Antilles",
    "New Caledonia": "New Caledonia",
    "New Zealand": "New Zealand",
    Nicaragua: "Nicaragua",
    Niger: "Niger",
    Nigeria: "Nigeria",
    Niue: "Niue",
    "Norfolk Island": "Norfolk Island",
    "Northern Mariana Islands": "Northern Mariana Islands",
    Norway: "Norway",
    Oman: "Oman",
    Pakistan: "Pakistan",
    Palau: "Palau",
    "Palestinian Territory, Occupied": "Palestinian Territory, Occupied",
    Panama: "Panama",
    "Papua New Guinea": "Papua New Guinea",
    Paraguay: "Paraguay",
    Peru: "Peru",
    Philippines: "Philippines",
    Pitcairn: "Pitcairn",
    Poland: "Poland",
    Portugal: "Portugal",
    "Puerto Rico": "Puerto Rico",
    Qatar: "Qatar",
    Reunion: "Reunion",
    Romania: "Romania",
    "Russian Federation": "Russian Federation",
    RWANDA: "RWANDA",
    "Saint Helena": "Saint Helena",
    "Saint Kitts and Nevis": "Saint Kitts and Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
    Samoa: "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome and Principe",
    "Saudi Arabia": "Saudi Arabia",
    Senegal: "Senegal",
    "Serbia and Montenegro": "Serbia and Montenegro",
    Seychelles: "Seychelles",
    "Sierra Leone": "Sierra Leone",
    Singapore: "Singapore",
    Slovakia: "Slovakia",
    Slovenia: "Slovenia",
    "Solomon Islands": "Solomon Islands",
    Somalia: "Somalia",
    "South Africa": "South Africa",
    "South Georgia and the South Sandwich Islands":
      "South Georgia and the South Sandwich Islands",
    Spain: "Spain",
    "Sri Lanka": "Sri Lanka",
    Sudan: "Sudan",
    Surilabel: "Surilabel",
    "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
    Swaziland: "Swaziland",
    Sweden: "Sweden",
    Switzerland: "Switzerland",
    "Syrian Arab Republic": "Syrian Arab Republic",
    "Taiwan, Province of China": "Taiwan, Province of China",
    Tajikistan: "Tajikistan",
    "Tanzania, United Republic of": "Tanzania, United Republic of",
    Thailand: "Thailand",
    "Timor-Leste": "Timor-Leste",
    Togo: "Togo",
    Tokelau: "Tokelau",
    Tonga: "Tonga",
    "Trinidad and Tobago": "Trinidad and Tobago",
    Tunisia: "Tunisia",
    Turkey: "Turkey",
    Turkmenistan: "Turkmenistan",
    "Turks and Caicos Islands": "Turks and Caicos Islands",
    Tuvalu: "Tuvalu",
    Uganda: "Uganda",
    Ukraine: "Ukraine",
    "United Arab Emirates": "United Arab Emirates",
    "United Kingdom": "United Kingdom",
    "United States": "United States",
    "United States Minor Outlying Islands":
      "United States Minor Outlying Islands",
    Uruguay: "Uruguay",
    Uzbekistan: "Uzbekistan",
    Vanuatu: "Vanuatu",
    Venezuela: "Venezuela",
    "Viet Nam": "Viet Nam",
    "Virgin Islands, British": "Virgin Islands, British",
    "Virgin Islands, U.S.": "Virgin Islands, U.S.",
    "Wallis and Futuna": "Wallis and Futuna",
    "Western Sahara": "Western Sahara",
    Yemen: "Yemen",
    Zambia: "Zambia",
    Zimbabwe: "Zimbabwe",
  },
  infoText: {
    name: "Name cannot contain special characters or numbers.",
    email: "Email Address should not be of more than 45 characters",
    special: "Special characters are not allowed in phone and postal code",
    phone: "Phone should not be of more than 15 numbers",
    address: "Address should not be of more than 199 characters",
    city: "City/State should not be of more than 20 characters",
    postalCode: "Postal Code should not be of more than 10 characters",
    memo: "Memo should not be of more than 255 characters",
  },
  countryCode: {
    JPY: "JPY",
    USD: "USD",
    HKD: "HKD",
    SGD: "SGD",
  },
  "Send Email": "Send Email",
  "Send Email_For_Ibec": "送信",
  "Or Pay With Card": "Or Pay With Card",
  "From The Button Below": "下記のボタンから",
  "You Can Also Pay": "決済することもできます",
  "Enable Apple Pay Text": `Appleアカウントに登録のクレジットカード情報を使用してお支払いできます。ご利用できるカードブランドは、<span style="color:red">Visa・Mastercard</span>です。`,
  "Enable Google Pay Text": `Googleアカウントに登録のクレジットカード情報を使用してお支払いできます。ご利用できるカードブランドは、<span style="color:red">Visa・Mastercard</span>です。`,
  "Apple Pay inactive on your device.":"Apple Pay inactive on your device."
};
