export const jp = {
  merchant: {
    Order_Overview: "注文の概要",
    Payment_Information: {
      Card_Details: "カード詳細",
      LabelAmount: "金額",
      AmountError: "数値は小数点以下 2 桁までにする必要があります",
      fields: {
        Name_on_Card: "カード名義",
        Name_on_Card_For_Ibec: "カード名義（半角英字)",
        FirstName: "姓（半角英字）",
        FirstName_For_Ibec: "名（例：TARO）",
        LastName: "名（半角英字)",
        LastName_For_Ibec: "姓（例：YAMADA）",
        CardNumber: "カード番号",
        Amount: "金額",
        CVV: "CVV",
        Card_Expiry: "カード有効期限",
        monthyear: "月/年",
        Memo: "メモ",
        MemoPlaceholder: "メモ (任意)",
      },
    },
    Billing_Information: {
      label: "請求情報",
      fields: {
        EmailAddress: "Eメールアドレス",
        Phone: "電話",
        BuildingNameStreetForIBEC: "都道府県",
        BuildingNameStreet: "都道府県",
        State: "都道府県",
        Select: "国",
        Postal_Code_Label: "郵便番号",
        Postal_Code_Label_For_Ibec: "郵便番号（ハイフンなし）",
        Postal_Code_Placeholder: "郵便番号（ハイフンなし）",
        Postal_Code_Placeholder_For_Ibec: "郵便番号",
        AddressForIBEC: "都道府県",
        Address: "住所",
        CityStateLabel: "都道府県",
        CityStateForIBEC: "市区町村",
        CityState: "市区町村 番地",
        Country: "国",
      },
    },
    button: {
      cancel: "キャンセル",
      PayNow: "お支払い",
      SubscribeNow: "今すぐ購読",
    },
    Empty_Popup_Please_Enter_An_Amount: "金額を入力してください",
  },
  error404: {
    code: "エラー404",
    message: "ページが見つかりません",
    description:
      "申し訳ありませんが、お探しのページが見つからないか、存在しません",
  },
  error500: {
    code: "エラー500",
    message: "サーバーエラーが発生しました。",
    description: "申し訳ありませんが、技術的な問題が発生しています。",
  },
  errorcancel499: {
    code: "エラー499",
    message: "この支払いはキャンセルされました。",
    heading: "お支払いはキャンセルされました。請求は行われません。",
    description_WithCancelUrl:
      "自動的に販売者のウェブサイトに移動します。そのページにアクセスしたくない場合は、ブラウザのタブを閉じてください。",
    description_WithoutCancelUrl: "ブラウザのタブを閉じてください。",
  },
  error400: {
    code: "エラー400",
    message: "不正なリクエスト",
    description: "サーバー内部エラー.",
  },
  Payment_Failed: {
    status: "支払いに失敗しました。",
    message: "の支払いを処理できませんでした",
    Prev_Link: "ご利用のサービスに戻る",
  },
  Payment_Successful: {
    status: "支払いが完了しました。",
    recipient: "支払先:",
    Amount_Paid: "払込金額 ：",
    Transaction_reference: "取引参照番号：",
    Payment_Method: "支払い方法",
    Prev_Link: "ご利用のサービスに戻る",
    Redirect_Prefix_Text: "このページは",
    Redirect_Suffix_Text: " 秒後にリダイレクトされます。",
  },
  Validation: {
    ExpiryDate: "有効期限は今日より後である必要があります。",
    InvalidCard: "このカードは有効ではありません",
  },
  ProductPopup: {
    Total_Price: "合計金額",
  },
  Email_ID: "メールID",
  Email_ID_For_Ibec: "メールアドレス",
  Email_Sent_Successfully: "決済完了メールを送信しました",
  Please_Enter_An_Email_ID_To_Receive_The_Receipt:
    "領収書を受け取るには、電子メールidを入力してください:",
  Please_Enter_An_Email_ID_To_Receive_The_Receipt_For_Ibec:
    "決済完了メールを受け取りたい場合は、メールアドレスを入力して「送信」を押してください。",
  Subscription: {
    label: {
      PlanName: "プラン名",
      BillingCycle: "請求サイクル",
      StartBillingDate: "請求開始日",
      EndBillingDate: "請求終了日",
      TrialPeriod: "お試し期間",
      Discount: "割引",
    },
    value: {
      ForTheFirst: "最初の",
      Discount: "割引",
      months: "か月",
      month: "か月",
    },
  },

  countryNames: {
    Afghanistan: "アフガニスタン",
    "Åland Islands": "オーランド諸島",
    Albania: "アルバニア",
    Algeria: "アルジェリア",
    "American Samoa": "アメリカ領サモア",
    Andorra: "アンドラ",
    Angola: "アンゴラ",
    Anguilla: "アンギラ",
    Antarctica: "南極",
    "Antigua and Barbuda": "アンティグア・バーブーダ",
    Argentina: "アルゼンチン",
    Armenia: "アルメニア",
    Aruba: "アルバ",
    Australia: "オーストラリア",
    Austria: "オーストリア",
    Azerbaijan: "アゼルバイジャン",
    Bahamas: "バハマ",
    Bahrain: "バーレーン",
    Bangladesh: "バングラデシュ",
    Barbados: "バルバドス",
    Belarus: "ベラルーシ",
    Belgium: "ベルギー",
    Belize: "ベリーズ",
    Benin: "ベニン",
    Bermuda: "バミューダ",
    Bhutan: "ブータン",
    Bolivia: "ボリビア",
    "Bosnia and Herzegovina": "ボスニア・ヘルツェゴビナ",
    Botswana: "ボツワナ",
    "Bouvet Island": "ブーベ島",
    Brazil: "ブラジル",
    "British Indian Ocean Territory": "英領インド洋地域",
    "Brunei Darussalam": "ブルネイ",
    Bulgaria: "ブルガリア",
    "Burkina Faso": "ブルキナファソ",
    Burundi: "ブルンジ",
    Cambodia: "カンボジア",
    Cameroon: "カメルーン",
    Canada: "カナダ",
    "Cape Verde": "カーボベルデ",
    "Cayman Islands": "ケイマン諸島",
    "Central African Republic": "中央アフリカ共和国",
    Chad: "チャド",
    Chile: "チリ",
    China: "中国",
    "Christmas Island": "クリスマス島",
    "Cocos (Keeling) Islands": "ココス（キーリング）諸島)",
    Colombia: "コロンビア",
    Comoros: "コモロ",
    Congo: "コンゴ共和国",
    "Congo, The Democratic Republic of the": "コンゴ民主共和国",
    "Cook Islands": "クック諸島",
    "Costa Rica": "コスタリカ",
    "Cote D'Ivoire": "コートジボワール",
    Croatia: "クロアチア",
    Cuba: "キューバ",
    Cyprus: "キプロス",
    "Czech Republic": "チェコ",
    Denmark: "デンマーク",
    Djibouti: "ジブチ",
    Dominica: "ドミニカ",
    "Dominican Republic": "ドミニカ共和国",
    Ecuador: "エクアドル",
    Egypt: "エジプト",
    "El Salvador": "エルサルバドル",
    "Equatorial Guinea": "赤道ギニア",
    Eritrea: "エリトリア",
    Estonia: "エストニア",
    Ethiopia: "エチオピア",
    "Falkland Islands (Malvinas)": "フォークランド諸島 (Falkland Islands)",
    "Faroe Islands": "フェロー諸島",
    Fiji: "フィジー",
    Finland: "フィンランド",
    France: "フランス",
    "French Guiana": "フランス領ギアナ",
    "French Polynesia": "フランス領ポリネシア",
    "French Southern Territories": "フランス領南方・南極地域",
    Gabon: "ガボン",
    Gambia: "ガンビア",
    Georgia: "ジョージア",
    Germany: "ドイツ",
    Ghana: "ガーナ",
    Gibraltar: "ジブラルタル",
    Greece: "ギリシャ",
    Greenland: "グリーンランド",
    Grenada: "グレナダ",
    Guadeloupe: "グアドループ",
    Guam: "グアム",
    Guatemala: "グアテマラ",
    Guernsey: "ガーニジー",
    Guinea: "ギニア",
    "Guinea-Bissau": "ギニアビサウ",
    Guyana: "ガイアナ",
    Haiti: "ハイチ",
    "Heard Island and Mcdonald Islands": "ハード島とマクドナルド諸島",
    "Holy See (Vatican City State)": "バチカン (Holy See)",
    Honduras: "ホンジュラス",
    "Hong Kong": "香港",
    Hungary: "ハンガリー",
    Iceland: "アイスランド",
    India: "インド",
    Indonesia: "インドネシア",
    "Iran,Islamic Republic Of": "イラン",
    Iraq: "イラク",
    Ireland: "アイルランド",
    "Isle of Man": "マン島",
    Israel: "イスラエル",
    Italy: "イタリア",
    Jamaica: "ジャマイカ",
    Japan: "日本",
    Jersey: "ジャージー",
    Jordan: "ヨルダン",
    Kazakhstan: "カザフスタン",
    Kenya: "ケニア",
    Kiribati: "キリバス",
    "Korea, Democratic People's Republic of": "北朝鮮",
    "Korea, Republic of": "韓国",
    Kuwait: "クウェート",
    Kyrgyzstan: "キルギスタン",
    "Lao People'S Democratic Republic": "ラオス",
    Latvia: "ラトビア",
    Lebanon: "レバノン",
    Lesotho: "レソト",
    Liberia: "リベリア",
    "Libyan Arab Jamahiriya": "リビア",
    Liechtenstein: "リヒテンシュタイン",
    Lithuania: "リトアニア",
    Luxembourg: "ルクセンブルク",
    Macao: "マカオ",
    "Macedonia, The Former Yugoslav Republic of": "北マケドニア",
    Madagascar: "マダガスカル",
    Malawi: "マラウイ",
    Malaysia: "マレーシア",
    Maldives: "モルディブ",
    Mali: "マリ",
    Malta: "マルタ",
    "Marshall Islands": "マーシャル諸島",
    Martinique: "マルティニーク",
    Mauritania: "モーリタニア",
    Mauritius: "モーリシャス",
    Mayotte: "マヨット",
    Mexico: "メキシコ",
    "Micronesia, Federated States of": "ミクロネシア連邦",
    "Moldova, Republic of": "モルドバ",
    Monaco: "モナコ",
    Mongolia: "モンゴル",
    Montserrat: "モントセラト",
    Morocco: "モロッコ",
    Mozambique: "モザンビーク",
    Myanmar: "ミャンマー",
    Namibia: "ナミビア",
    Nauru: "ナウル",
    Nepal: "ネパール",
    Netherlands: "オランダ",
    "Netherlands Antilles": "オランダ領アンティル",
    "New Caledonia": "ニューカレドニア",
    "New Zealand": "ニュージーランド",
    Nicaragua: "ニカラグア",
    Niger: "ニジェール",
    Nigeria: "ナイジェリア",
    Niue: "ニウエ",
    "Norfolk Island": "ノーフォーク島",
    "Northern Mariana Islands": "北マリアナ諸島",
    Norway: "ノルウェー",
    Oman: "オマーン",
    Pakistan: "パキスタン",
    Palau: "パラオ",
    "Palestinian Territory, Occupied": "パレスチナ",
    Panama: "パナマ",
    "Papua New Guinea": "パプアニューギニア",
    Paraguay: "パラグアイ",
    Peru: "ペルー",
    Philippines: "フィリピン",
    Pitcairn: "ピトケアン",
    Poland: "ポーランド",
    Portugal: "ポルトガル",
    "Puerto Rico": "プエルトリコ",
    Qatar: "カタール",
    Reunion: "レユニオン",
    Romania: "ルーマニア",
    "Russian Federation": "ロシア",
    Rwanda: "ルワンダ",
    "Saint Helena": "セントヘレナ",
    "Saint Kitts and Nevis": "セントクリストファー・ネイビス",
    "Saint Lucia": "セントルシア",
    "Saint Pierre and Miquelon": "セントピエール・ミクロン",
    "Saint Vincent and the Grenadines": "セントビンセント・グレナディーン諸島",
    Samoa: "サモア",
    "San Marino": "サンマリノ",
    "Sao Tome and Principe": "サントメ・プリンシペ",
    "Saudi Arabia": "サウジアラビア",
    Senegal: "セネガル",
    "Serbia and Montenegro": "セルビア・モンテネグロ",
    Seychelles: "セーシェル",
    "Sierra Leone": "シエラレオネ",
    Singapore: "シンガポール",
    Slovakia: "スロバキア",
    Slovenia: "スロベニア",
    "Solomon Islands": "ソロモン諸島",
    Somalia: "ソマリア",
    "South Africa": "南アフリカ",
    "South Georgia and the South Sandwich Islands":
      "サウスジョージア・サウスサンドウィッチ諸島",
    Spain: "スペイン",
    "Sri Lanka": "スリランカ",
    Sudan: "スーダン",
    Suriname: "スリナム",
    "Svalbard and Jan Mayen": "スヴァールバル諸島・ヤンマイエン島",
    Swaziland: "エスワティニ",
    Sweden: "スウェーデン",
    Switzerland: "スイス",
    "Syrian Arab Republic": "シリア",
    "Taiwan, Province of China": "台湾",
    Tajikistan: "タジキスタン",
    "Tanzania, United Republic of": "タンザニア",
    Thailand: "タイ",
    "Timor-Leste": "東ティモール",
    Togo: "トーゴ",
    Tokelau: "トケラウ",
    Tonga: "トンガ",
    "Trinidad and Tobago": "トリニダード・トバゴ",
    Tunisia: "チュニジア",
    Turkey: "トルコ",
    Turkmenistan: "トルクメニスタン",
    "Turks and Caicos Islands": "タークス・カイコス諸島",
    Tuvalu: "ツバル",
    Uganda: "ウガンダ",
    Ukraine: "ウクライナ",
    "United Arab Emirates": "アラブ首長国連邦",
    "United Kingdom": "イギリス",
    "United States": "アメリカ合衆国",
    "United States Minor Outlying Islands": "アメリカ合衆国外諸島",
    Uruguay: "ウルグアイ",
    Uzbekistan: "ウズベキスタン",
    Vanuatu: "バヌアツ",
    Venezuela: "ベネズエラ",
    "Viet Nam": "ベトナム",
    "Virgin Islands, British": "ヴァージン諸島（英国領）",
    "Virgin Islands, U.S.": "ヴァージン諸島（米国領）",
    "Wallis and Futuna": "ワリス・フツナ",
    "Western Sahara": "西サハラ",
    Yemen: "イエメン",
    Zambia: "ザンビア",
    Zimbabwe: "ジンバブエ",
  },
  infoText: {
    name: "名前には特殊文字や数字を含めることはできません。",
    email: "メールアドレスは45文字を超えてはいけません",
    special: "電話番号と郵便番号に特殊文字は使用できません",
    phone: "電話番号は15桁を超えてはいけません",
    address: "住所は199文字を超えてはいけません。",
    city: "市区町村/都道府県は20文字を超えてはいけません。",
    postalCode: "郵便番号は10文字を超えてはいけません。",
    memo: "メモは255文字を超えてはいけません",
  },
  countryCode: {
    JPY: "円",
    USD: "USD",
    HKD: "HKD",
    SGD: "SGD",
  },
  "Send Email": "メール送信",
  "Send Email_For_Ibec": "送信",
  "Or Pay With Card": "または下記の項目を入力",
  "From The Button Below": "下記のボタンから",
  "You Can Also Pay": "決済することもできます",
  "Enable Apple Pay Text": `Appleアカウントに登録のクレジットカード情報を使用してお支払いできます。ご利用できるカードブランドは、<span style="color:red">Visa・Mastercard</span>です。`,
  "Enable Google Pay Text": `Googleアカウントに登録のクレジットカード情報を使用してお支払いできます。ご利用できるカードブランドは、<span style="color:red">Visa・Mastercard</span>です。`,
  "Apple Pay inactive on your device.":"この環境ではApple Payをご利用できません"
};
